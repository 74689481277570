import './style.scss'
import {useEffect, useRef, useState} from "react";

function Home() {
    const video1 = useRef(null);
    const video2 = useRef(null);
    const [current, setCurrent] = useState(false)
    useEffect(() => {
        if (current === false) {
            video1.current.style.zIndex = 1
            video2.current.style.zIndex = 3
            video2.current.play()
            video2.current.addEventListener('ended', () => {
                setCurrent(true)
            });
        } else {
            video2.current.style.zIndex = 1
            video1.current.style.zIndex = 3
            video1.current.play()
            video1.current.addEventListener('ended', () => {
                setCurrent(false)
            });
        }
    }, [current])
    return (
        <div className={`homewrap`}>
            <div className="home">
                <div className="center">
                    <h1 className="aniText">一站式AIGC互动营销SAAS工具及解决方案</h1>
                    <p className="aniText">BIMOAI让企业获客更容易</p>
                    <button className="aniText move-ani" onClick={() => window.open(window.CONFIG.console)}>
                        立即开始
                        <span className="icon iconfont bm-arrow_bend_20"></span>
                    </button>
                </div>
            </div>
            <div className="video">
                <video
                    playsInline
                    ref={video1}
                    muted
                    src={window.ASSETS.home_video2}
                />
                <video
                    playsInline
                    ref={video2}
                    muted
                    src={window.ASSETS.home_video}
                />
            </div>
            <div className="bottom-mask">

            </div>
        </div>
    )
}

export default Home